<!-- 报名记录———报名 -->
<template>
    <div class="signState">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">报名记录(准考证)</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单部分 -->
        <div class="" style="flex: 1;">
            <div class="info">
                报名信息
            </div>
            <el-form ref="form" :model="form" class="form" inline label-width="150px">
                <el-form-item label="状态">
                    <el-input :value="form.condition" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="登记单号">
                    <el-input :value="form.number" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="机构名称">
                    <el-input :value="form.fullName" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="考生姓名">
                    <el-input :value="form.name" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="指导老师">
                    <el-input :value="form.teacher" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input :value="form.telephone" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="科目">
                    <el-input :value="form.subject" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="原等级">
                    <el-input :value="form.primaryRank" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="报考等级">
                    <el-input :value="form.signRank" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="考级报名费">
                    <el-input :value="form.regFee" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="副证费用">
                    <el-input :value="form.viceFee" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="应缴考试费">
                    <el-input :value="form.should" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="考试场次">
                    <el-input :value="form.testSite" placeholder="" disabled></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input :value="form.remarks" placeholder="" disabled></el-input>
                </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <div class="info">
                    考试安排
                </div>
                <el-form ref="form_2" :model="form_2" class="form" inline label-width="150px">
                    <el-form-item label="状态">
                        <el-input :value="form_2.state" placeholder="" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="安排单号">
                        <el-input :value="form_2.planNum" placeholder="" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="考场名称">
                        <el-input :value="form_2.roomName" placeholder="" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="考场地址">
                        <el-input :value="form_2.address" placeholder="" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="考试日期">
                        <el-input :value="form_2.testdate" placeholder="" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="开始时间">
                        <el-input :value="form_2.start_time" placeholder="" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="结束时间">
                        <el-input :value="form_2.end_time" placeholder="" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="考试时长">
                        <el-input :value="form_2.duration" placeholder="" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="准考证号">
                        <el-input :value="form_2.admission" placeholder="" disabled></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <el-divider></el-divider>
            <div class="buttom">
                <div class="">
                    &copy2015 深圳书画学院 考生考级报名系统 All rights reserved
                </div>
                <div class="">
                    服务热线：0755-8883 0206
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    condition:"",//状态
                    number:"",//登记单号
                    fullName:"",//机构名称
                    name:"",//考生姓名
                    teacher:"",//指导老师
                    telephone:"",//联系电话
                    subject:"",//科目
                    primaryRank:"",//原等级
                    signRank:"",//报考等级
                    regFee:"",//考级报名费
                    viceFee:"",//副证费用
                    should:"",//应缴考试费
                    testSite:"",//考试场次
                    remarks:""//备注
                },
                form_2:{
                    state:"",//状态
                    planNum:"",//安排单号
                    roomName:"",//考场名称
                    address:"",//考场地址
                    testdate:"",//考试日期
                    start_time:"",//开始时间
                    end_time:"",//结束时间
                    duration:"",//考试时长
                    admission:""//准考证号 
                }
            }
        },
        created() {
            this.$request({url:'/api/studentexam/signupDetail',method:"POST",data:{
                uid: localStorage.getItem('token'),
                signup_id:this.$route.query.id
            }}).then(res=>{
                console.log(res)
                let data = res.data
                this.form.condition=data.status=='1'?'草稿':data.status=='2'?'报名':'准考'
                this.form.number=data.enroll_number
                this.form.fullName=data.org_name
                this.form.name=data.name
                this.form.teacher=data.teacher
                this.form.telephone=data.phone
                this.form.subject=data.course
                this.form.primaryRank=data.original_level
                this.form.signRank=data.exam_level
                this.form.regFee=data.enroll_price
                this.form.viceFee=data.certificatecopy_price
                this.form.should=data.price1
                this.form.testSite=data.apply
                this.form.remarks=data.remark
            })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
        }
        
    }
</script>

<style scoped="scoped">
    .signState{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 59px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 报名信息部分 */
    .info {
        font-size: 18px;
        font-weight: bold;
        width: 150px;
        text-align: right;
        padding-bottom: 30px;
    }
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    .form ::v-deep .el-input.is-disabled .el-input__inner{
        background-color: #FFFFFF;
        color: #333333;
        border-color:#ccc;
        cursor: default;
    }
    /* 考试安排 */
    .scoreinfo {
        font-size: 20px;
        font-weight: bold;
        width: 240px;
        padding-top: 30px;
        text-align: right;
    }
    /* 底部 */
    .buttom{
        height: 20px;
        line-height: 20px;
        padding: 0px 25px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
    }
</style>
